import { Component, OnInit, EventEmitter, ViewChild, ElementRef, Input, Output } from "@angular/core";
import { ChabadHouse } from "../chabad-houses/ChabadHouse";
import { Action } from "../action-view/action.model";

@Component({
  selector: "ch-closest-item",
  templateUrl: "./ch-closest-item.component.html",
  styleUrls: ["./ch-closest-item.component.css"]
})
export class ChClosestItemComponent implements OnInit {
  @ViewChild("item") item: ElementRef;
  @Output() onActionClick: EventEmitter<Action> = new EventEmitter();
  @Output() onItemClick: EventEmitter<any> = new EventEmitter();
  @Input("index") index: number;
  @Input("chabadHouse") chabadHouse: ChabadHouse;
  position: Function;
  closestCHTitle = "בית חבד הקרוב אליך";

  constructor() {

  }

  ngOnInit() {

  }

  onActionClicked(action: Action): void {
    this.onActionClick.next(action);
  }

  onItemClicked(): void {
    this.onItemClick.next({index: this.index, ch: this.chabadHouse});
  }
}
