import { Component, OnInit, NgZone } from '@angular/core';
import { Contact } from "../chabad-houses/Contact";
import { ChabadHousesService } from "../service/chabadhouses.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {

  id: string;
  contact: Contact;

  constructor(private zone: NgZone,
              private route: ActivatedRoute,
              private  chabadHouseService: ChabadHousesService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params["id"];
      this.chabadHouseService.getContactById(this.id)
        .subscribe((contact: Contact) => {
            this.zone.run(() => {
              this.contact = contact;
              /*this.metaService.setTitle(this.contact.displayName);
              this.metaService.setTag('description',this.contact.displayName);
              this.metaService.setTag('keywords',this.contact.search.join(" "));*/
            });
          },
          (error: any) => {
            this.zone.run(() => {
              console.log(error);
            });
          });
    });
  }

}
