import { Component } from "@angular/core";
import { Angulartics2GoogleAnalytics } from "angulartics2";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  toolbarIcon = "menu";
  // toolbarIcon = "navigate_before";
  appName = "חב״ד בעולם";

  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {

  }
}
