import {Component, OnInit} from "@angular/core";

@Component({
  selector: "app-chabad-world",
  templateUrl: "./chabad-world.component.html",
  styleUrls: ["./chabad-world.component.css"]
})
export class ChabadWorldComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

  title = {};
}
