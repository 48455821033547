import {Component, OnInit, NgZone} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ChabadHousesService} from "../service/chabadhouses.service";
import {ChabadHouse} from "../chabad-houses/ChabadHouse";
import {CH_INFO_TYPES} from "../providers/providers";
import {SubShliach} from "../chabad-houses/SubShliach";

@Component({
  selector: "app-chabad-house-page",
  templateUrl: "./chabad-house-page.component.html",
  styleUrls: ["./chabad-house-page.component.css"]
})
export class ChabadHousePageComponent implements OnInit {

  CH_INFO_TYPES = CH_INFO_TYPES;
  id: string;
  chabadHouse: ChabadHouse;
  subShliach: SubShliach[];

  constructor(private zone: NgZone,
              private route: ActivatedRoute,
              private  chabadHouseService: ChabadHousesService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.id = params["id"];
      this.chabadHouseService.getChabadHouseById(this.id)
        .subscribe((chabadHouse: ChabadHouse) => {
            this.zone.run(() => {
              this.chabadHouse = new ChabadHouse(chabadHouse);
              /*this.metaService.setTitle(this.chabadHouse.institute);
              this.metaService.setTag("description",this.chabadHouse.institute + " " + this.chabadHouse.shliach);
              this.metaService.setTag("keywords",this.chabadHouse.search.join(" "));
              this.getSubShliach(new ChabadHouse(chabadHouse));*/
            });
          },
          (error: any) => {
            this.zone.run(() => {
              console.log(error);
            });
          });
    });
  }

  private getSubShliach(chabadHouse: ChabadHouse) {
    this.chabadHouseService.getSubShliach(chabadHouse.id)
      .subscribe((subShliach: SubShliach[]) => {
          this.zone.run(() => {
            this.subShliach = subShliach;
          });
        },
        (error: any) => {
          this.zone.run(() => {
            console.log(error);
          });
        });
  }

}
