import { Component, OnInit, NgZone, EventEmitter, Output, Input } from "@angular/core";
import { ChabadHousesService } from "../service/chabadhouses.service";
import { GeolocationService } from "../service/geolocation";
import { ChabadHouse } from "../chabad-houses/ChabadHouse";
import { Contact } from "../chabad-houses/Contact";
import { FullAddress } from "../models/FullAddress";
import { MyLatLng } from "../models/LatLng";

@Component({
  selector: "app-search-input",
  templateUrl: "./search-input.component.html",
  styleUrls: ["./search-input.component.css"]
})
export class SearchInputComponent implements OnInit {
  @Input("searchPlaceHolder") searchPlaceHolder: string;
  loadingSearch: boolean;
  @Output() searchMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchResults: EventEmitter<ChabadHouse[]> = new EventEmitter<ChabadHouse[]>();
  @Output() contactsResults: EventEmitter<Contact[]> = new EventEmitter<Contact[]>();
  @Input("searchState") searchState: string;

  constructor(private zone: NgZone,
              private chabadHouseServie: ChabadHousesService,
              private geolocationService: GeolocationService) {
  }

  ngOnInit() {

  }

  searchChabadHouses(search: string): void {
    this.loadingSearch = true;
    if (this.searchState === "location") {

    } else if (this.searchState === "ch") {
      this.chabadHouseServie.search(search, this.geolocationService.getLastLocation())
        .subscribe((results: ChabadHouse[]) => {
            this.zone.run(() => {
              if (results.length > 0) {
                this.searchMode.next(true);
                this.searchResults.next(results);
              }
              this.loadingSearch = false;
            });
          },
          (error: any) => {
            this.zone.run(() => {
              console.log(error);
              this.loadingSearch = false;
            });
          });
    } else if (this.searchState === "pb") {
      this.chabadHouseServie.searchPb(search)
        .subscribe((results: Contact[]) => {
            this.zone.run(() => {
              if (results.length > 0) {
                this.searchMode.next(true);
                this.contactsResults.next(results);
              }
              this.loadingSearch = false;
            });
          },
          (error: any) => {
            this.zone.run(() => {
              console.log(error);
              this.loadingSearch = false;
            });
          });
    }
  }


  searchByLocation(fullAddress: FullAddress) {
    const location = new MyLatLng(fullAddress.location[0], fullAddress.location[1]);
    this.chabadHouseServie.searchByLocation(location)
      .subscribe((results: ChabadHouse[]) => {
          this.zone.run(() => {
            if (results.length > 0) {
              this.searchMode.next(true);
              this.searchResults.next(results);
            }
            this.loadingSearch = false;
          });
        },
        (error: any) => {
          this.zone.run(() => {
            console.log(error);
            this.loadingSearch = false;
          });
        });
  }
}
