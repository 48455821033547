import {Component, OnInit, NgZone} from '@angular/core';
import {Contact} from "../chabad-houses/Contact";
import {ChabadHousesService} from "../service/chabadhouses.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'contact-item',
  templateUrl: 'contact-item.component.html',
  styleUrls: ['contact-item.component.css'],
  inputs:['contact']
})
export class ContactItemComponent implements OnInit {

  contact:Contact;

  constructor() {
  }

  ngOnInit() {

  }

  shareWhatsappLink():void {
    window.open(this.contact.shareLink);
  }
}
