import {Component, OnInit, EventEmitter} from '@angular/core';
import {ChabadHouse} from "../chabad-houses/ChabadHouse";
import {Action} from "../action-view/action.model";

@Component({
  selector: 'chabad-house-item-actions',
  templateUrl: './chabad-house-item-actions.component.html',
  styleUrls: ['./chabad-house-item-actions.component.css'],
  inputs:['chabadHouse'],
  outputs:['onActionClickedSearch']
})
export class ChabadHouseItemActionsComponent implements OnInit {

  onActionClickedSearch:EventEmitter<Action> = new EventEmitter();

  MORE = 0;
  CALL = 1;
  MAIL = 2;
  NAVIGATE = 3;

  chabadHouse: ChabadHouse;

  constructor() {
  }

  ngOnInit() {
  }

  onActionClicked(action: Action): void {
    this.onActionClickedSearch.next(action);
  }
}
