import {Component, OnInit, EventEmitter} from '@angular/core';
import {ChabadHouse} from "../chabad-houses/ChabadHouse";
import {Action} from "../action-view/action.model";

@Component({
  selector: 'chabad-house-item',
  templateUrl: './chabad-house-item.component.html',
  styleUrls: ['./chabad-house-item.component.css'],
  inputs: ['chabadHouse', 'index'],
  outputs: ['onActionClick']
})
export class ChabadHouseItemComponent implements OnInit {

  onActionClick: EventEmitter<Action> = new EventEmitter();

  chabadHouse: ChabadHouse;
  index: number;

  constructor() {
  }

  ngOnInit() {
  }

  onActionClicked(action: Action): void {
    this.onActionClick.next(action);
  }

}
