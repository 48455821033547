export class SubShliach {
  id: string;
  hasPicture: boolean;
  additionalPhone: string;
  phone: string;
  shliach: string;
  institute: string;
  jobTitle: string;
  email: string;
  shliachEn: boolean;
  picture: any;
  imgSrc: string;

  constructor(subShliach?: any) {
    this.id = subShliach.id;
    this.hasPicture = subShliach.get("hasPicture") || false;
    this.additionalPhone = subShliach.get("additionalPhone") || "";
    this.phone = subShliach.get("phone") || "";
    this.shliach = subShliach.get("shliach") || "";
    this.institute = subShliach.get("institute") || "";
    this.jobTitle = subShliach.get("jobTitle") || "";
    this.email = subShliach.get("email") || false;
    this.shliachEn = subShliach.get("shliachEn") || null;
    this.picture = subShliach.get("picture") || null;
    this.imgSrc = this.picture && this.picture.url() || "assets/images/no_chabad_house.jpg";
  }
}
