import {Component, OnInit, EventEmitter} from '@angular/core';
import {Action} from "./action.model";
import {ChabadHouse} from "../chabad-houses/ChabadHouse";

@Component({
  selector: 'app-action-view',
  templateUrl: './action-view.component.html',
  styleUrls: ['./action-view.component.css'],
  inputs: ['action', 'chabadHouse'],
  outputs: ['onActionClick'],
})
export class ActionViewComponent implements OnInit {

  chabadHouse: ChabadHouse;
  onActionClick: EventEmitter<Action> = new EventEmitter<Action>();

  MORE = 0;
  CALL = 1;
  MAIL = 2;
  NAVIGATE = 3;

  imagePath: string;
  action: number;
  title: string;

  constructor() {

  }

  ngOnInit() {
    switch (this.action) {
      case this.MORE:
        this.title = "עוד";
        this.imagePath = "assets/images/more_branded.png";
        break;
      case this.MAIL:
        this.title = "מייל";
        this.imagePath = "assets/images/mail_branded.png";
        break;
      case this.CALL:
        this.title = "התקשר";
        this.imagePath = "assets/images/phone_branded.png";
        break;
      case this.NAVIGATE:
        this.title = "נווט";
        this.imagePath = "assets/images/location_branded.png";
        break;
    }
  }

  actionClick(): void {
    let scheme: string;
    switch (this.action) {
      case this.MORE:
        scheme = "more";
        break;
      case this.MAIL:
        scheme = "mailto:" + this.chabadHouse.email;
        break;
      case this.CALL:
        scheme = "tel:" + this.chabadHouse.phone;
        break;
      case this.NAVIGATE:
        scheme = `waze://?ll=${this.chabadHouse.location.latitude},${this.chabadHouse.location.longitude}`;
        break;
    }
    this.onActionClick.next(new Action(this.action, scheme, this.chabadHouse));
  }
}
