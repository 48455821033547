import {Component, OnInit} from '@angular/core';
import {SubShliach} from "../chabad-houses/SubShliach";

@Component({
  selector: 'sub-shliach',
  templateUrl: './sub-shliach.component.html',
  styleUrls: ['./sub-shliach.component.css'],
  inputs: ['subShliach']
})
export class SubShliachComponent implements OnInit {
  subShliach: SubShliach;

  constructor() {
  }

  ngOnInit() {
  }

}
