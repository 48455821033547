import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import { ChabadWorldComponent } from "./chabad-world/chabad-world.component";
import { ChabadHousesComponent } from "./chabad-houses/chabad-houses.component";
import { PhoneBookComponent } from "./phone-book/phone-book.component";
import { AgmCoreModule } from "@agm/core";
import { LocalStorageModule } from "angular-2-local-storage";
import { SearchInputComponent } from "./search-input/search-input.component";
import { ChClosestItemComponent } from "./ch-closest-item/ch-closest-item.component";
import { IndicatorsComponent } from "./indicators/indicators.component";
import { ActionViewComponent } from "./action-view/action-view.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { Routes, RouterModule } from "@angular/router";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
import {
  geolocationServiceInjectables,
  chabadHousesServiceInjectables,
  parseServiceInjectables,
  locationStrategyInjectables
} from "./providers/providers";
import { ChabadHousePageComponent } from "./chabad-house-page/chabad-house-page.component";
import { ChabadHouseItemComponent } from "./chabad-house-item/chabad-house-item.component";
import { ChabadHouseItemActionsComponent } from "./chabad-house-item-actions/chabad-house-item-actions.component";
import { ChInfoComponent } from "./ch-info/ch-info.component";
import { SubShliachComponent } from "./sub-shliach/sub-shliach.component";
import { ContactItemComponent } from "./contact-item/contact-item.component";
import { Angulartics2GoogleAnalytics, Angulartics2Module } from "angulartics2";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SafeUrlPipe } from "./pipes/SafeUrlPipe";
import { AddChabadHouseComponent } from "./add-chabad-house/add-chabad-house.component";
import { WorldMapComponent } from "./world-map/world-map.component";
import { ChabadHouseNearbyComponent } from "./chabad-house-nearby/chabad-house-nearby.component";
import {
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatButtonModule,
  MatChipsModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from "@angular/material";
import { PlacesAutocompleateComponent } from "./places-autocompleate/places-autocompleate.component";

const routes: Routes = [
  {path: "", redirectTo: "home", pathMatch: "full"},
  {path: "home", component: ChabadWorldComponent},
  {path: "about", component: AboutUsComponent},
  {path: "contact/:id", component: ContactPageComponent},
  {path: "chabadhouse/:id", component: ChabadHousePageComponent},
  {path: "chabadhouses/add", component: AddChabadHouseComponent},
  {path: "map", component: AddChabadHouseComponent}
];


/*const metaConfig: MetaConfig = {
 //Append a title suffix such as a site name to all titles
 //Defaults to false
 useTitleSuffix: true,
 defaults: {
 title: "חב״ד בעולם - כל בתי חב״ד וחסידי חבד במקום אחד",
 titleSuffix: " | חב״ד בעולם"
 }
 };*/


@NgModule({
  declarations: [
    AppComponent,
    ChabadWorldComponent,
    ChabadHousesComponent,
    PhoneBookComponent,
    SearchInputComponent,
    ChClosestItemComponent,
    IndicatorsComponent,
    ActionViewComponent,
    SideMenuComponent,
    AboutUsComponent,
    ContactItemComponent,
    ChabadHousePageComponent,
    ChabadHouseItemComponent,
    ChabadHouseItemActionsComponent,
    ChInfoComponent,
    SubShliachComponent,
    ContactItemComponent,
    ContactPageComponent,
    SafeUrlPipe,
    AddChabadHouseComponent,
    WorldMapComponent,
    ChabadHouseNearbyComponent,
    PlacesAutocompleateComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCbvJLITCDkArYds7sHivkliHYPqSyBxfE",
      libraries: ["places"]
    }),
    RouterModule.forRoot(routes),
    BrowserModule,
    FormsModule,
    HttpModule,
    MatButtonModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    LocalStorageModule.withConfig({
      prefix: "cw",
      storageType: "localStorage"
    }),
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    FlexLayoutModule
  ],
  providers: [
    geolocationServiceInjectables,
    chabadHousesServiceInjectables,
    parseServiceInjectables,
    locationStrategyInjectables
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
