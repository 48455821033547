import {
  Component, OnInit, NgZone, ViewChild
} from "@angular/core";
import { GeolocationService } from "../service/geolocation";
import { LocalStorageService } from "angular-2-local-storage";
import { ChabadHousesService } from "../service/chabadhouses.service";
import { MyLatLng } from "../models/LatLng";
import { Action } from "../action-view/action.model";
import { Router } from "@angular/router";
import { ChabadHouse } from "../chabad-houses/ChabadHouse";
import { FullAddress } from "../models/FullAddress";
import { AgmMap } from "@agm/core";

@Component({
  selector: "app-chabad-house-nearby",
  templateUrl: "./chabad-house-nearby.component.html",
  styleUrls: ["./chabad-house-nearby.component.css"]
})
export class ChabadHouseNearbyComponent implements OnInit {

  MORE = 0;
  CALL = 1;
  MAIL = 2;
  NAVIGATE = 3;
  chabadHouses: ChabadHouse[];
  chabadHousesSearchResults: ChabadHouse[];
  searchMode: boolean;
  searchPlaceHolder = "הזן עיר, מדינה, שליח, מיקוד";
  lastLocation: MyLatLng = new MyLatLng(31.771959, 35.190144);
  public zoom: number = 14;
  searchState = "location";
  searchAddress: string;
  address: any;
  streetNumber: any;
  street: any;
  city: any;
  state: any;
  district: any;
  country: any;
  postalCode: any;
  location = [2];
  name: any;
  placeId: any;
  url: any;
  types: any;
  utcOffset: any;
  countryCodes: any;
  @ViewChild("map") map: AgmMap;

  constructor(private router: Router,
              private zone: NgZone,
              private geolocationService: GeolocationService,
              private localStorageService: LocalStorageService,
              private chabadHousesService: ChabadHousesService) {
  }

  ngOnInit() {
    // this.lastLocation = this.geolocationService.getLastLocation();
    this.geolocationService.getLocation(null)
      .subscribe((position: any) => {

          this.localStorageService.set("lastLat", position.coords.latitude);
          this.localStorageService.set("lastLng", position.coords.longitude);
          this.lastLocation.latiitude = position.coords.latitude;
          this.lastLocation.longitude = position.coords.longitude;
          this.getClosestChabadHouses();
        },
        (error: any) => {
          console.log(error);
        },
        () => {
        });
    this.getClosestChabadHouses();
  }

  private getClosestChabadHouses() {
    this.chabadHousesService.getClosestChabadHouses(this.lastLocation)
      .subscribe(
        (results: ChabadHouse[]) => { // on sucesss
          this.zone.run(() => { // <== added
            this.chabadHouses = results;

            let latSum = 0;
            let lngSum = 0;

            results.forEach((ch) => {
              latSum += ch.location.latitude;
              lngSum += ch.location.longitude;
            });

            this.lastLocation.latiitude = latSum / results.length;
            this.lastLocation.longitude = lngSum / results.length;

          });
        },
        (err: any) => { // on error
          console.log(err);
        },
        () => { // on completion
        }
      );
  }

  updateSearchMode(searchMode: boolean): void {
    this.searchMode = searchMode;
  }

  updateChabadHouseSearchResults(searchResults: ChabadHouse[]): void {
    this.chabadHousesSearchResults = searchResults;
  }

  onChabadHouseActionClick(action: Action): void {

    if (action.action === this.MORE) {
      this.router.navigate(["/chabadhouse", action.chabadHouse.id]);
    } else if (action.action === this.MAIL) {
      window.open(action.scheme);
    } else if (action.action === this.CALL) {
      window.open(action.scheme);
    } else if (action.action === this.NAVIGATE) {
      window.open(action.scheme);
    }
  }

  onMarkerClick(obj: any, index: number): void {

  }

  onClosestItemClick(obj): void {
    this.lastLocation.latiitude = obj.ch.location.latitude;
    this.lastLocation.longitude = obj.ch.location.longitude;
  }

  setAddress(point: string, address: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.address = address;
    }
  }

  setStreetNumber(point: string, streetNumber: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.streetNumber = streetNumber;
    }
  }

  setStreet(point: string, street: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.street = street;
    }
  }

  setCity(point: string, city: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.city = city;
    }
  }

  setState(point: string, state: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.state = state;
    }
  }

  setDistrict(point: string, district: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.district = district;
    }
  }

  setCountry(point: string, country: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.country = country;
    }
  }

  setPostalCode(point: string, postalCode: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.postalCode = postalCode;
    }
  }

  setLat(point: string, lat: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.location[0] = lat;
    }
  }

  setLng(point: string, lng: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.location[1] = lng;
    }
  }

  setName(point: string, name: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.name = name;
    }
  }

  setPlaceId(point: string, placeId: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.placeId = placeId;
    }
  }

  setTypes(point: string, types: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.types = types;
    }
  }

  setUrl(point: string, url: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.url = url;
    }
  }

  setUtcOffset(point: string, utcOffset: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.utcOffset = utcOffset;
    }
  }

  setCountryCodes(point: string, countryCodes: any): void {
    if (point === "start") {
    } else if (point === "end") {
      this.countryCodes = countryCodes;
    }
  }

  updateLocation(location: FullAddress) {
    // this.searchObject.location = location;
    // this.searchSubject.next(this.searchObject);
    console.log(location);
  }
}
