import {Component, OnInit} from '@angular/core';
import {ChabadHouse} from "../chabad-houses/ChabadHouse";
import {CH_INFO_TYPES} from "../providers/providers";

@Component({
  selector: 'ch-info',
  templateUrl: './ch-info.component.html',
  styleUrls: ['./ch-info.component.css'],
  inputs: ['title', 'chabadHouse', 'action']
})
export class ChInfoComponent implements OnInit {
  action: number;
  title: string;
  icon: string;
  chabadHouse: ChabadHouse;
  CH_INFO_TYPES = CH_INFO_TYPES;

  constructor() {
  }

  ngOnInit() {
    if (this.action === 0) {
      this.icon = "assets/images/location_branded.png";
    } else if (this.action === this.CH_INFO_TYPES.CELL_PHONE) {
      this.icon = "assets/images/phone_branded.png";
    } else if (this.action === this.CH_INFO_TYPES.ADDITIONAL_PHONE) {
      this.icon = "assets/images/phone_branded.png";
    } else if (this.action === this.CH_INFO_TYPES.HOME_PHONE) {
      this.icon = "assets/images/home_phone.png";
    } else if (this.action === this.CH_INFO_TYPES.FAX) {
      this.icon = "assets/images/fax.png";
    } else if (this.action === this.CH_INFO_TYPES.MAIL) {
      this.icon = "assets/images/mail_branded.png";
    } else if (this.action === this.CH_INFO_TYPES.WEBSITE) {
      this.icon = "assets/images/web.png";
    } else if (this.action === this.CH_INFO_TYPES.FACEBOOK) {
      this.icon = "assets/images/facebook_branded.png";
    } else if (this.action === this.CH_INFO_TYPES.SHARE) {
      this.title = "שתף";
      this.icon = "assets/images/share.png";
    } else if (this.action === this.CH_INFO_TYPES.EDIT) {
      this.title = "שלח הצעה לעריכה";
      this.icon = "assets/images/edit_branded.png";
    }
  }

}
