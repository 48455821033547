export class Contact {
  id: string;
  PhoneOne: string;
  PhoneTwo: string;
  PhoneThree: string;
  email: string;
  fax: string;
  wifeName: string;
  wifeEmail: string;
  wifePhone: string;
  lastName: string;
  hebrewName: string;
  englishName: string;
  address: string;
  enAddress: string;
  city: string;
  enCity: string;
  hasPicture: boolean;
  picture: any;
  imgSrc: string;
  displayName: string;
  public location: any;
  shareLink: string;
  wazeUrl: string;
  search: string[];

  constructor(contact?: any) {
    this.id = contact.id;
    this.PhoneOne = contact.get("PhoneOne") || '';
    this.PhoneTwo = contact.get("PhoneTwo") || '';
    this.PhoneThree = contact.get("PhoneThree") || '';
    this.email = contact.get("email") || '';
    this.fax = contact.get("fax") || '';
    this.wifeName = contact.get("wifeName") || '';
    this.wifeEmail = contact.get("wifeEmail") || '';
    this.wifePhone = contact.get("wifePhone") || '';
    this.lastName = contact.get("lastName") || '';
    this.hebrewName = contact.get("hebrewName") || '';
    this.englishName = contact.get("englishName") || '';
    this.address = contact.get("address") || '';
    this.location = contact.get("location") || null;
    this.enAddress = contact.get("enAddress") || '';
    this.city = contact.get("city") || '';
    this.enCity = contact.get("enCity") || '';
    this.hasPicture = contact.get("hasPicture") || false;
    this.picture = contact.get("picture") || null;
    this.search = contact.get("search") || null;
    this.imgSrc = this.picture && this.picture.url() || 'assets/images/contact.jpg';

    this.displayName = this.lastName + " " + this.hebrewName || this.englishName;
    if (this.wifeName) {
      this.displayName += " ו" + this.wifeName;
    }
    this.wazeUrl = `waze://?ll=${this.location.latitude},${this.location.longitude}`;
    this.shareLink = `whatsapp://send?text=https://bati.chabad.am/contact/${this.id}`;
  }
}
