export class FullAddress {
  searchAddress: string;
  address: any;
  streetNumber: any;
  street: any;
  city: any;
  state: any;
  district: any;
  country: any;
  postalCode: any;
  location = [2];
  name: any;
  placeId: any;
  url: any;
  types: any;
  utcOffset: any;
  countryCodes: any;
}
