import {ParseManager} from "../models/ParseManager";
import {ChabadHousesService} from "../service/chabadhouses.service";
import {GeolocationService} from "../service/geolocation";
import {LocationStrategy, HashLocationStrategy} from "@angular/common";

export var chabadHousesServiceInjectables: Array<any> = [{provide: ChabadHousesService, useClass: ChabadHousesService}];
export var parseServiceInjectables: Array<any> = [{provide: ParseManager, useClass: ParseManager}];
export var geolocationServiceInjectables: Array<any> = [{provide: GeolocationService, useClass: GeolocationService}];
export var locationStrategyInjectables: Array<any> = [{provide: LocationStrategy, useClass: HashLocationStrategy}];
export var CH_INFO_TYPES = {
  ADDRESS: 0,
  CELL_PHONE: 1,
  ADDITIONAL_PHONE: 2,
  HOME_PHONE: 3,
  FAX: 4,
  MAIL: 5,
  WEBSITE: 6,
  FACEBOOK: 7,
  SHARE: 8,
  EDIT: 9
};

