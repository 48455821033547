import { Component, OnInit } from "@angular/core";
import { Contact } from "../chabad-houses/Contact";

@Component({
  selector: "phone-book",
  templateUrl: "./phone-book.component.html",
  styleUrls: ["./phone-book.component.css"],
  inputs: ["searchPlaceHolder", "searchMode"]
})
export class PhoneBookComponent implements OnInit {

  searchState = "pb";
  searchMode: boolean;
  searchPlaceHolder: string = "הזן שם, משפחה, עיר";
  contacts: Contact[];

  constructor() {
  }

  ngOnInit() {
  }

  updateSearchMode(searchMode: boolean): void {
    this.searchMode = searchMode;
  }

  updateContactsSearchResults(results: Contact[]): void {
    this.contacts = results;
  }
}
