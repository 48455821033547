// <reference path="../../typings/tsd.d.ts" />

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ChabadHouse } from "../chabad-houses/ChabadHouse";
import { MyLatLng } from "./LatLng";
import { SubShliach } from "../chabad-houses/SubShliach";
import { Contact } from "../chabad-houses/Contact";
// import {} from 'parse'
// import * as Parse from "parse";
declare var require: any;
let Parse = require('parse');

// const Parse: any = require("parse");

Parse.initialize("l4yxbpUB4w2my958uNWIm2zXVoAoudGZ9uTT18gl");
Parse.serverURL = "https://bati.chabad.am/parse";

@Injectable()
export class ParseManager {
  constructor() {

  }

  getChabadHouseById(id: string): Observable<any> {
    return new Observable(observer => {
      const CH = Parse.Object.extend("chabadHouses");
      const query = new Parse.Query(CH);
      query.get(id, {
        success: (ch) => {
          observer.next(ch);
        },
        error: (object, error) => {
          observer.error(error);
        }
      });
    });
  }

  getContactById(id: string): Observable<Contact> {
    return new Observable(observer => {
      const PB = Parse.Object.extend("PhoneBook");
      const query = new Parse.Query(PB);
      query.get(id, {
        success: (contact) => {
          observer.next(new Contact(contact));
        },
        error: (object, error) => {
          observer.error(error);
        }
      });
    });
  }

  getSubShliach(chabadHouseId: string): Observable<SubShliach[]> {
    return new Observable(observer => {
      const SS = Parse.Object.extend("SubShliach");
      const query = new Parse.Query(SS);
      query.equalTo("chId", chabadHouseId);
      query.notEqualTo("confirmed", false);
      query.limit(1000);
      query.find({
        success: (ss) => {
          let subShlichim = [];
          ss.forEach((element) => {
            subShlichim.push(new SubShliach(element));
          });
          observer.next(subShlichim);
        },
        error: (object, error) => {
          observer.error(error);
        }
      });
    });
  }

  getClosestChabadHouse(location: MyLatLng): Observable<ChabadHouse[]> {
    return new Observable(observer => {
      const params = {
        position: {lat: location.latiitude, lon: location.longitude},
        user: "itzharInfoShraga",
        type: "closest",
        count: 3,
        host: "https://bati.chabad.am/"
      };
      Parse.Cloud.run("getClosestChabadHouse", params).then((result) => {
        let chabadHouses = [];
        result.forEach((element) => {
          chabadHouses.push(new ChabadHouse(element));
        });
        observer.next(chabadHouses);
      });
    });
  }

  search(val: string, location?: MyLatLng): Observable<ChabadHouse[]> {
    return new Observable(observer => {
      const params = {
        user: "itzharInfoShraga",
        search: val,
        type: "search",
        position: {lat: location.latiitude, lon: location.longitude},
        host: "https://bati.chabad.am",
      };
      Parse.Cloud.run("chabadHouseWebSearch", params).then((result) => {
        let chabadHouses = [];
        result.forEach((element) => {
          chabadHouses.push(new ChabadHouse(element));
        });
        observer.next(chabadHouses);
      });
    });
  }

  searchByLocation(location?: MyLatLng): Observable<ChabadHouse[]> {
    return new Observable(observer => {
      const params = {
        user: "itzharInfoShraga",
        type: "search",
        count: 20,
        position: {lat: location.latiitude, lon: location.longitude},
        host: "https://bati.chabad.am",
      };
      Parse.Cloud.run("getClosestChabadHouse", params).then((result) => {
        let chabadHouses = [];
        result.forEach((element) => {
          chabadHouses.push(new ChabadHouse(element));
        });
        observer.next(chabadHouses);
      });
    });
  }

  searchPb(val: string): Observable<Contact[]> {
    return new Observable(observer => {
      const pb = Parse.Object.extend("PhoneBook");
      const query = new Parse.Query(pb);
      query.containsAll("search", val.toLocaleLowerCase().split(" "));
      query.notEqualTo("confirmed", false);
      query.find({
        success: (contactsArray) => {
          let contacts = [];
          contactsArray.forEach((element) => {
            contacts.push(new Contact(element));
          });
          observer.next(contacts);
        },
        error: (object, error) => {
          observer.error(error);
        }
      });
    });
  }
}
