export class ChabadHouse {
  id:string;
  comments:string;
  fax:string;
  hebCountry:string;
  hebCity:string;
  institute:string;
  instituteEn:string;
  shliach:string;
  shliachEn:string;
  hasPicture:boolean;
  public location:any;
  picture:any;
  web:string;
  email:string;
  pob:string;
  zipCode:string;
  facebook:string;
  phone:string;
  additionalPhone:string;
  phoneHome:string;
  public address:string;
  enAddress:string;
  imgSrc:string;
  isEditable:boolean;
  search:string[];

  constructor(chabadHouse?:any) {
    this.id = chabadHouse.id;
    this.comments = chabadHouse.get("comments") || '';
    this.hebCountry = chabadHouse.get("hebCountry") || '';
    this.hebCity = chabadHouse.get("hebCity") || '';
    this.institute = chabadHouse.get("institute") || '';
    this.instituteEn = chabadHouse.get("instituteEn") || '';
    this.shliach = chabadHouse.get("shliach") || '';
    this.shliachEn = chabadHouse.get("shliachEn") || '';
    this.hasPicture = chabadHouse.get("hasPicture") || false;
    this.location = chabadHouse.get("location") || null;
    this.picture = chabadHouse.get("picture") || null;
    this.web = chabadHouse.get("webSite") || '';
    this.email = chabadHouse.get("email") || '';
    this.pob = chabadHouse.get("pob") || '';
    this.facebook = chabadHouse.get("facebook") || '';
    this.phone = chabadHouse.get("phone") || '';
    this.additionalPhone = chabadHouse.get("additionalPhone") || '';
    this.address = chabadHouse.get("address") || '';
    this.enAddress = chabadHouse.get("enAddress") || '';
    this.phoneHome = chabadHouse.get("phoneHome") || '';
    this.zipCode = chabadHouse.get("zipcode") || '';
    this.search = chabadHouse.get("search") || '';
    this.fax = chabadHouse.get("fax") || '';
    this.isEditable = chabadHouse.get("isEditable") || true;
    this.imgSrc = this.picture && this.picture.url() || 'assets/images/no_chabad_house.jpg';
  }
}
