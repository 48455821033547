import {Injectable} from "@angular/core";
import {ParseManager} from "../models/ParseManager";
import {Observable} from "rxjs";
import {ChabadHouse} from "../chabad-houses/ChabadHouse";
import {MyLatLng} from "../models/LatLng";
import {SubShliach} from "../chabad-houses/SubShliach";
import {Contact} from "../chabad-houses/Contact";
import {LocalStorageService} from "angular-2-local-storage";

@Injectable()
export class ChabadHousesService {

  CLOSEST_CHABAD_HOUSES_1 = "last1";
  CLOSEST_CHABAD_HOUSES_2 = "last2";
  CLOSEST_CHABAD_HOUSES_3 = "last3";

  constructor(private parseManager: ParseManager, private localStorageService: LocalStorageService) {

  }

  getClosestChabadHouses(location: MyLatLng): Observable<ChabadHouse[]> {
    return this.parseManager.getClosestChabadHouse(location);
  }

  getChabadHouseById(id: string): Observable<any> {
    return this.parseManager.getChabadHouseById(id);
  }

  getContactById(id: string) : Observable<Contact> {
    return this.parseManager.getContactById(id);
  }

  getSubShliach(chabadHouseId: string): Observable<SubShliach[]> {
    return this.parseManager.getSubShliach(chabadHouseId);
  }

  search(val: string, location?: any): Observable<ChabadHouse[]> {
    return this.parseManager.search(val, location);
  }

  searchByLocation(location?: any): Observable<ChabadHouse[]> {
    return this.parseManager.searchByLocation(location);
  }

  searchPb(val: string): Observable<Contact[]> {
    return this.parseManager.searchPb(val);
  }

  saveLastClosestChabadHouses(results: ChabadHouse[]) {
    /*this.localStorageService.set(this.CLOSEST_CHABAD_HOUSES_1, JSON.stringify(results[0]));
    this.localStorageService.set(this.CLOSEST_CHABAD_HOUSES_2, JSON.stringify(results[1]));
    this.localStorageService.set(this.CLOSEST_CHABAD_HOUSES_3, JSON.stringify(results[2]));*/
  }

  getLastClosestChabadHouses(): ChabadHouse[] {
    /*var item1 = this.localStorageService.get(this.CLOSEST_CHABAD_HOUSES_1);
    var item2 = this.localStorageService.get(this.CLOSEST_CHABAD_HOUSES_2);
    var item3 = this.localStorageService.get(this.CLOSEST_CHABAD_HOUSES_3);*/
    let lastClosestChabadHousesString:ChabadHouse[] = [];
    /*console.log(item1);
    console.log(item2);
    console.log(item3);
    lastClosestChabadHousesString.push(new ChabadHouse(item1));
    lastClosestChabadHousesString.push(new ChabadHouse(item2));
    lastClosestChabadHousesString.push(new ChabadHouse(item3));
    console.log(lastClosestChabadHousesString);*/
    return lastClosestChabadHousesString;
  }


}
