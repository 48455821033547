import {Component, OnInit, EventEmitter} from '@angular/core';
import {MenuCategory} from "./menu.model";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
  outputs: ['onItemClick']
})
export class SideMenuComponent implements OnInit {
  onItemClick: EventEmitter<boolean> = new EventEmitter();
  categories = [
    new MenuCategory('כללי', [{name: 'ראשי', rout: 'home/'}, {name: 'הוסף בית חב״ד', rout: 'chabadhouses/add'}]),
    new MenuCategory('חב״ד בעולם', [{name: 'אודותינו', rout: 'about'}])
  ];

  constructor() {
  }

  ngOnInit() {
  }


  onItemClicked(): void {
    this.onItemClick.next(true);
  }
}
