import {
  Component, OnInit, NgZone, ViewChild, Input
} from "@angular/core";
import { GeolocationService } from "../service/geolocation";
import { LocalStorageService } from "angular-2-local-storage";
import { ChabadHousesService } from "../service/chabadhouses.service";
import { ChabadHouse } from "./ChabadHouse";
import { MyLatLng } from "../models/LatLng";
import { Action } from "../action-view/action.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-chabad-houses",
  templateUrl: "./chabad-houses.component.html",
  styleUrls: ["./chabad-houses.component.css"]
})
export class ChabadHousesComponent implements OnInit {

  @Input("searchMode") searchMode: boolean;

  MORE = 0;
  CALL = 1;
  MAIL = 2;
  NAVIGATE = 3;
  public zoom: number = 14;
  chabadHouses: ChabadHouse[];
  chabadHousesSearchResults: ChabadHouse[];
  searchPlaceHolder = "הזן עיר, מדינה, שליח, מיקוד";
  lastLocation: MyLatLng;
  searchState = "ch";

  constructor(private router: Router,
              private zone: NgZone,
              private geolocationService: GeolocationService,
              private localStorageService: LocalStorageService,
              private chabadHousesService: ChabadHousesService) {
  }

  ngOnInit() {

    this.lastLocation = this.geolocationService.getLastLocation();
    this.geolocationService.getLocation(null)
      .subscribe((position: any) => {
          this.localStorageService.set("lastLat", position.coords.latitude);
          this.localStorageService.set("lastLng", position.coords.longitude);
          this.lastLocation.latiitude = position.coords.latitude;
          this.lastLocation.longitude = position.coords.longitude;
          this.getClosestChabadHouses();
        },
        (error: any) => {
          console.log(error);
        },
        () => {

        });
    this.getClosestChabadHouses();
  }

  private getClosestChabadHouses() {
    this.chabadHousesService.getClosestChabadHouses(this.lastLocation)
      .subscribe(
        (results: ChabadHouse[]) => { // on sucesss
          this.zone.run(() => { // <== added
            this.chabadHouses = results;

            let latSum = 0;
            let lngSum = 0;
            results.forEach(ch => {
              latSum += ch.location.latitude;
              lngSum += ch.location.longitude;
            });

            this.lastLocation.latiitude = latSum / results.length;
            this.lastLocation.longitude = lngSum / results.length;

          });
        },
        (err: any) => { // on error
          console.log(err);
        },
        () => { // on completion
        }
      );
  }

  updateSearchMode(searchMode: boolean): void {
    this.searchMode = searchMode;
  }

  updateChabadHouseSearchResults(searchResults: ChabadHouse[]): void {
    this.chabadHousesSearchResults = searchResults;
  }

  onChabadHouseActionClick(action: Action): void {

    if (action.action === this.MORE) {
      this.router.navigate(["/chabadhouse", action.chabadHouse.id]);
    } else if (action.action === this.MAIL) {
      window.open(action.scheme);
    } else if (action.action === this.CALL) {
      window.open(action.scheme);
    } else if (action.action === this.NAVIGATE) {
      window.open(action.scheme);
    }
  }

  onMarkerClick(obj: any, index: number): void {

  }

  onClosestItemClick(obj): void {
    this.lastLocation.latiitude = obj.ch.location.latitude;
    this.lastLocation.longitude = obj.ch.location.longitude;
  }
}
